@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bg: #282c34;
  --header: #fff;
  --link: #61dafb;
  --text: hsla(0, 0%, 100%, 0.88);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

body {
  background-color: "background";
  font-family: "Roboto", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.top-shadow {
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1),
    0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.image-container {
  /* width: 100%; */
  height: 18rem;
  /* background-repeat: repeat; */
  /* background-size: cover; */
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Adjust height for larger screens */
@media (min-width: 640px) {
  .navbar {
    height: 6rem; /* Adjust height for medium-sized screens */
    padding-top: 1.5rem; /* Add space between NavLinks and top of navbar */
  }
}

@media (min-width: 1024px) {
  .navbar {
    height: 4rem; /* Adjust height for larger screens */
    padding-top: 1rem; /* Add more space between NavLinks and top of navbar */
  }
}

/* .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
} */

/* .responsive-image { */
/* max-width: 100%; */
/* height: auto; */
/* } */
